<template>
  <v-container>
    <v-card>
      <v-card-title>
        {{ clusterInfos.name }} ({{ clusterInfos.id }})
      </v-card-title>
      <v-divider />
      <v-row no-gutters>
        <v-col cols="12" sm="6">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>{{
                clusterInfos.type_cluster
              }}</v-list-item-title>
              <v-list-item-subtitle>Cluster Type</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="6">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>{{
                clusterInfos.start_date | moment("lll")
              }}</v-list-item-title>
              <v-list-item-subtitle>Creation Time</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
      <v-divider />
      <v-row no-gutters v-if="clusterInfos.address">
        <v-col cols="12" sm="6">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>{{
                clusterInfos.address.address_line_1
              }}</v-list-item-title>
              <v-list-item-subtitle>Address Line 1</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="6">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>{{
                clusterInfos.address.address_line_2
              }}</v-list-item-title>
              <v-list-item-subtitle>Address Line 2</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="6">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>{{
                clusterInfos.address.postcode
              }}</v-list-item-title>
              <v-list-item-subtitle>Post Code</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="6">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>{{
                clusterInfos.address.city
              }}</v-list-item-title>
              <v-list-item-subtitle>City</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="6">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>{{
                clusterInfos.address.country
              }}</v-list-item-title>
              <v-list-item-subtitle>Country</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
      <v-divider />
      <v-subheader>Default Rules</v-subheader>
      <v-simple-table dense v-if="clusterInfos.default_rules">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Name</th>
              <th class="text-right">Value</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="keyField in Object.keys(clusterInfos.default_rules)"
              :key="keyField"
            >
              <td>{{ keyField }}</td>
              <td class="text-right">
                <v-icon
                  v-if="clusterInfos.default_rules[keyField]"
                  color="success"
                  dense
                  >mdi-thumb-up</v-icon
                >
                <v-icon v-else color="error" dense>mdi-thumb-down</v-icon>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success"> edit </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: ['id'],
  mounted() {
    this.refresh();
  },
  watch: {
    id(val) {
      if (val) {
        this.refresh();
      }
    },
  },
  computed: {
    ...mapGetters(['clusterInfos']),
  },
  methods: {
    ...mapActions(['getClustersInfos']),
    refresh() {
      this.getClustersInfos({ idCluster: this.id });
    },
  },
};
</script>
