<template>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        dense></v-text-field>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        small
        @click="dialogAdd = true"
        >ADD</v-btn
      >
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="filteredClusters"
      :items-per-page="10"
      dense>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          dense
          @click="$router.push(`/clusters/${item.id}`)">
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialogAdd"
      fullscreen
      persistent>
      <add-cluster @close="dialogAdd = false" />
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import addCluster from '../components/Clusters/addCluster.vue'

export default {
  components: {
    addCluster,
  },
  data() {
    return {
      dialogAdd: false,
      search: sessionStorage.getItem('searchCluster') || '',
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Actions',
          sortable: false,
          value: 'actions',
          align: 'right',
        },
      ],
    }
  },
  watch: {
    search(val) {
      sessionStorage.setItem('searchCluster', val)
    },
  },
  computed: {
    ...mapGetters(['clusters']),
    filteredClusters() {
      const tps = [...this.clusters].filter((elem) => {
        if (this.search) {
          const includeName = elem.name.toLowerCase().includes(this.search.toLowerCase())
          const includeId = elem.id.toLowerCase().includes(this.search.toLowerCase())
          return includeName || includeId
        }

        return true
      })
      return tps
    },
  },
  mounted() {
    this.getClusters()
  },
  methods: {
    ...mapActions(['getClusters']),
  },
}
</script>
