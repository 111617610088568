import Api from '@/services/Api'

export default {
  getUserLoginCode(idUser) {
    return Api().get(`usersLoginCode?id=${idUser}`)
  },
  getUserLoginCodeV3(idUser) {
    return Api().get(`usersLoginCode/V3?id=${idUser}`)
  },
}
