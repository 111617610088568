<template>
  <v-card>
    <v-card-title> ADD Members: {{idCluster}} </v-card-title>

    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" step="1"> Emails </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="e1 > 2" step="2"> Rights </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="e1 > 3" step="3"> Machines </v-stepper-step>
      </v-stepper-header>
      <v-alert type="error" v-if="error">{{ error }}</v-alert>
      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card flat class="my-1">
            <v-textarea
              name="input-7-1"
              label="Members emails"
              hint="Separate the different email addresses with ;"
              outlined
              v-model="emailInput"
            ></v-textarea>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" text small @click="close">Cancel</v-btn>
              <v-btn
                color="primary"
                :disabled="!emailInput"
                small
                @click="goToStep2()"
              >
                Continue
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="2">
          <v-card flat class="my-1">
            <v-container fluid>
              <v-row>
                <v-col cols="12" md="6">
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Name</th>
                          <th class="text-right">Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="keyField in Object.keys(editRights).slice(
                            0,
                            Math.ceil(Object.keys(editRights).length / 2)
                          )"
                          :key="keyField"
                          @click="editRights[keyField] = !editRights[keyField]"
                        >
                          <td>{{ keyField }}</td>
                          <td class="text-right">
                            <v-icon
                              v-if="editRights[keyField]"
                              color="success"
                              dense
                              >mdi-thumb-up</v-icon
                            >
                            <v-icon v-else color="error" dense
                              >mdi-thumb-down</v-icon
                            >
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
                <v-col cols="12" md="6">
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Name</th>
                          <th class="text-right">Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="keyField in Object.keys(editRights).slice(
                            Math.ceil(Object.keys(editRights).length / 2)
                          )"
                          :key="keyField"
                          @click="editRights[keyField] = !editRights[keyField]"
                        >
                          <td>{{ keyField }}</td>
                          <td class="text-right">
                            <v-icon
                              v-if="editRights[keyField]"
                              color="success"
                              dense
                              >mdi-thumb-up</v-icon
                            >
                            <v-icon v-else color="error" dense
                              >mdi-thumb-down</v-icon
                            >
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>
              <v-btn color="primary" small @click="e1 = 1"> Back </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="error" text small @click="close">Cancel</v-btn>
              <v-btn color="primary" small @click="goToStep3()">
                Continue
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="3">
          <v-card flat class="my-1">
            <v-card-text> Select machines: coming soon </v-card-text>
            <v-card-text>
              Currently all machines are added if member_can_view_machine is
              true
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" small @click="e1 = 2"> Back </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="error" text small @click="close">Cancel</v-btn>
              <v-btn
                color="primary"
                small
                @click="addMembers"
                :loading="loading"
              >
                ADD MEMBERS
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: ['idCluster'],
  data() {
    return {
      loading: false,
      e1: 1,
      emailInput: '',
      emails: [],
      error: '',
      defaultRights: {
        member_can_edit_cluster_informations: false,
        member_can_delete_cluster: false,
        member_can_add_members: false,
        member_can_remove_members: false,
        member_can_accept_members: false,
        member_can_view_members: false,
        member_can_create_machine: false,
        member_can_view_machine: false,
        member_can_edit_machine: false,
        member_can_delete_machine: false,
        member_can_manage_machine: false,
        member_can_add_counter_to_machine: false,
        member_can_remove_counter_to_machine: false,
        member_can_book_machine: false,
        member_can_book_machine_for_other: false,
        member_can_accept_book_machine: false,
        member_can_auto_accept_own_book_machine: false,
        member_can_delete_book_machine: false,
        member_can_delete_book_machine_for_other: false,
        member_can_view_real_time_machine: false,
        member_can_modify_activity_machine: false,
        member_can_view_all_activities_machine: false,
        member_can_book_employee: false,
        member_can_view_employee: false,
        member_can_accept_book_employee: false,
        member_can_book_employee_for_other: false,
        member_can_edit_book_employee: false,
        member_can_see_employee_activities: false,
        member_can_add_counter: false,
        member_can_edit_counter: false,
        member_can_see_counter: false,
      },
      editRights: {
        member_can_edit_cluster_informations: false,
        member_can_delete_cluster: false,
        member_can_add_members: false,
        member_can_remove_members: false,
        member_can_accept_members: false,
        member_can_view_members: false,
        member_can_create_machine: false,
        member_can_view_machine: false,
        member_can_edit_machine: false,
        member_can_delete_machine: false,
        member_can_manage_machine: false,
        member_can_add_counter_to_machine: false,
        member_can_remove_counter_to_machine: false,
        member_can_book_machine: false,
        member_can_book_machine_for_other: false,
        member_can_accept_book_machine: false,
        member_can_auto_accept_own_book_machine: false,
        member_can_delete_book_machine: false,
        member_can_delete_book_machine_for_other: false,
        member_can_view_real_time_machine: false,
        member_can_modify_activity_machine: false,
        member_can_view_all_activities_machine: false,
        member_can_book_employee: false,
        member_can_view_employee: false,
        member_can_accept_book_employee: false,
        member_can_book_employee_for_other: false,
        member_can_edit_book_employee: false,
        member_can_see_employee_activities: false,
        member_can_add_counter: false,
        member_can_edit_counter: false,
        member_can_see_counter: false,
      },
    };
  },
  computed: {
    isValidEmails() {
      if (this.emails.length === 0) return false;
      const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const badEmail = this.emails.find((email) => !regexEmail.test(email));
      return !badEmail;
    },
  },
  methods: {
    ...mapActions(['addMembersClusters']),
    reset() {
      this.e1 = 1;
      this.error = '';
      this.emailInput = '';
      this.emails = [];
      Object.keys(this.editRights).forEach((elem) => {
        this.editRights[elem] = this.defaultRights[elem];
      });
    },
    addMembers() {
      this.loading = true;

      this.addMembersClusters({
        idCluster: this.idCluster,
        emails: [...this.emails],
        rules: { ...this.editRights },
      });
      this.close();

      this.loading = false;
    },
    close() {
      this.$emit('close');
      this.reset();
    },
    goToStep2() {
      this.stringToEmailArray();
      this.error = '';
      if (this.isValidEmails) {
        this.e1 = 2;
      } else {
        this.error = 'Bad Email';
      }
    },
    goToStep3() {
      this.e1 = 3;
    },
    stringToEmailArray() {
      this.emails = [
        ...new Set(
          this.emailInput
            .split(/(?:,| |;|\n)+/)
            .map((email) => email.trim().toLowerCase())
            .filter((email) => !!email),
        ),
      ];
    },
  },
};
</script>
