<template>
  <v-card>
    <v-card-title> ADD Cluster </v-card-title>
    <v-container class="pt-4">
      <v-row dense>
        <v-col cols="12" sm="6">
          <v-text-field
            dense
            label="Name"
            v-model="editCluster.name"
            autocomplete="cluster_name"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            dense
            :items="items"
            label="Cluster Type"
            v-model="editCluster.type_cluster"
          ></v-select>
        </v-col>
      </v-row>
      <v-row dense class="my-4">
        <v-col cols="12" sm="6">
          <v-text-field
            dense
            label="Address 1"
            v-model="editCluster.address.address_line_1"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            dense
            label="Address 2"
            v-model="editCluster.address.address_line_2"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            dense
            label="Post Code"
            v-model="editCluster.address.postcode"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            dense
            label="City"
            v-model="editCluster.address.city"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            dense
            label="Country"
            v-model="editCluster.address.country"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-subheader>Default Rules</v-subheader>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Name</th>
              <th class="text-right">Value</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="keyField in Object.keys(editCluster.default_rules)"
              :key="keyField"
              @click="
                editCluster.default_rules[keyField] =
                  !editCluster.default_rules[keyField]
              "
            >
              <td>{{ keyField }}</td>
              <td class="text-right">
                <v-icon
                  v-if="editCluster.default_rules[keyField]"
                  color="success"
                  dense
                  >mdi-thumb-up</v-icon
                >
                <v-icon v-else color="error" dense>mdi-thumb-down</v-icon>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-row dense class="my-4">
        <v-col cols="12">
          <admin-group-autocomplete v-model="editCluster.adminGroup" />
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="error" small @click="close">Cancel</v-btn>
      <v-btn
        color="success"
        :loading="loadingSave"
        :disabled="!isValidForm"
        small
        @click="save"
        >Save</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import adminGroupAutocomplete from '../selectors/adminGroupAutocomplete.vue';

export default {
  components: { adminGroupAutocomplete },
  data() {
    return {
      loadingSave: false,
      editCluster: {
        adminGroup: [],
        name: '',
        type_cluster: '',
        address: {
          address_line_1: '',
          address_line_2: '',
          city: '',
          postcode: '',
          country: '',
        },
        default_rules: {
          all_member_can_edit_cluster_informations: false,
          all_member_can_delete_cluster: false,
          all_member_can_add_members: false,
          all_member_can_remove_members: false,
          all_member_can_accept_members: false,
          all_member_can_view_machines: false,
          all_member_can_create_machine: false,
          all_member_can_edit_machine: false,
          all_member_can_delete_machine: false,
          all_member_can_manage_machine: false,
          all_member_can_book_machine: false,
          all_member_can_accept_book_machine: false,
          all_member_can_view_real_time_machine: false,
          all_member_can_modify_activity_machine: false,
          all_member_can_auto_accept_own_book_machine: false,
          all_member_can_book_employee: false,
          all_member_can_view_employee: false,
          all_member_can_accept_book_employee: false,
          all_member_can_book_employee_for_other: false,
          all_member_can_edit_book_employee: false,
          all_member_can_see_employee_activities: false,
          all_member_can_add_counter: false,
          all_member_can_edit_counter: false,
        },
      },
      defaultCluster: {
        adminGroup: [],
        name: '',
        type_cluster: '',
        address: {
          address_line_1: '',
          address_line_2: '',
          city: '',
          postcode: '',
          country: '',
        },
        default_rules: {
          all_member_can_edit_cluster_informations: false,
          all_member_can_delete_cluster: false,
          all_member_can_add_members: false,
          all_member_can_remove_members: false,
          all_member_can_accept_members: false,
          all_member_can_view_machines: false,
          all_member_can_create_machine: false,
          all_member_can_edit_machine: false,
          all_member_can_delete_machine: false,
          all_member_can_manage_machine: false,
          all_member_can_book_machine: false,
          all_member_can_accept_book_machine: false,
          all_member_can_view_real_time_machine: false,
          all_member_can_modify_activity_machine: false,
          all_member_can_auto_accept_own_book_machine: false,
          all_member_can_book_employee: false,
          all_member_can_view_employee: false,
          all_member_can_accept_book_employee: false,
          all_member_can_book_employee_for_other: false,
          all_member_can_edit_book_employee: false,
          all_member_can_see_employee_activities: false,
          all_member_can_add_counter: false,
          all_member_can_edit_counter: false,
        },
      },
      items: ['farm', 'sharing', 'enterprise'],
    };
  },
  computed: {
    isValidForm() {
      if (!this.editCluster.name) return false;
      if (!this.editCluster.type_cluster) return false;
      if (!this.editCluster.address.address_line_1) return false;
      if (!this.editCluster.address.city) return false;
      if (!this.editCluster.address.postcode) return false;
      if (!this.editCluster.address.country) return false;
      if (!(this.editCluster.adminGroup && this.editCluster.adminGroup.length > 0)) return false;
      return true;
    },
  },
  methods: {
    ...mapActions(['createCluster']),
    clear() {
      Object.keys(this.editCluster.default_rules).forEach((elem) => {
        this.editCluster.default_rules[elem] = this.defaultCluster.default_rules[elem];
      });
      Object.keys(this.editCluster.address).forEach((elem) => {
        this.editCluster.address[elem] = this.defaultCluster.address[elem];
      });
      this.editCluster.name = this.defaultCluster.name;
      this.editCluster.type_cluster = this.defaultCluster.type_cluster;
      this.editCluster.adminGroup = this.defaultCluster.adminGroup;
    },
    close() {
      document.getElementsByClassName('v-dialog--active')[0].scrollTop = 0;
      this.$emit('close');
      this.clear();
    },
    async save() {
      this.loadingSave = true;
      try {
        const newCluster = await this.createCluster({ ...this.editCluster });
        this.close();

        this.$router.push(`/clusters/${newCluster.id}`);
      } catch (error) {
        console.log(error);
      }
      this.loadingSave = false;
    },
  },
};
</script>
