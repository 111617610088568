<template>
  <div>
    <v-app-bar
      fixed
      app
      flat
      color="primary"
      dark>
      <v-btn
        icon
        @click="$router.push('/clusters')">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="hidden-sm-and-down">Cluster</v-toolbar-title>
      <v-autocomplete
        :items="clusters"
        item-text="name"
        item-value="id"
        dense
        solo
        hide-details
        hide-selected
        color="white"
        v-model="cluster"
        class="ml-2" />
      <template v-slot:extension>
        <v-tabs
          centered
          grow
          background-color="primary"
          dark
          color="yellow">
          <v-tab
            exact
            :to="`/clusters/${cluster}`"
            >Infos</v-tab
          >
          <v-tab
            exact
            :to="`/clusters/${cluster}/members`"
            >Members</v-tab
          >
        </v-tabs>
      </template>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: ['id'],
  data() {
    return {
      cluster: '',
    }
  },
  computed: {
    ...mapGetters(['clusters']),
  },
  created() {
    this.getClusters()
  },
  mounted() {
    this.cluster = this.id
  },
  watch: {
    cluster(val) {
      this.$router.push({ name: this.$route.name, params: { id: val } }).catch(() => {})
    },
  },
  methods: {
    ...mapActions(['getClusters']),
  },
}
</script>
