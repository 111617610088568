<template>
  <v-autocomplete
    multiple
    item-text="name"
    item-value="id"
    :items="adminAccount"
    v-model="selectedAdmin"
    autocomplete="cluster_auto"
    label="Admins"
  >
    <template v-slot:selection="data">
      <v-chip small v-bind="data.attrs" close @click:close="remove(data.item)">
        {{ data.item.name }}
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    value: Array,
    default: () => [],
  },
  data() {
    return {
      selectedAdmin: this.value,
    };
  },
  watch: {
    value(val) {
      this.selectedAdmin = val;
    },
    selectedAdmin(val) {
      this.$emit('input', val);
    },
  },
  computed: {
    ...mapGetters(['adminAccount']),
  },
  created() {
    this.getAdminAccounts();
  },
  methods: {
    ...mapActions(['getAdminAccounts']),
    remove(item) {
      const index = this.selectedAdmin.indexOf(item.id);
      if (index >= 0) this.selectedAdmin.splice(index, 1);
    },
  },
};
</script>
